import cx from 'classnames';
// eslint-disable-next-line no-shadow
export enum EBubbleTextPosition {
  Left = 0,
  Right = 1,
}

export interface BubbleTextProps {
  avatar?: {
    presignedUrl: string | undefined;
  };
  isCharacterFrame?: boolean;
  isThinkBubble?: boolean;
  position: EBubbleTextPosition;
  text: string;
}

const BubbleText = ({ avatar, isCharacterFrame, isThinkBubble, text, position }: BubbleTextProps) => {
  const { presignedUrl } = avatar || {};
  const hasAvatar = Boolean(presignedUrl);

  const bubbleTextClasses = cx('c-text-message', {
    'c-text-message--right-aligned': position === EBubbleTextPosition.Right,
    'c-text-message--left-aligned': position === EBubbleTextPosition.Left,
  });

  const bubbleTextContentClasses = cx({
    'c-text-message__bubble--frame': isCharacterFrame,
    'c-text-message__think-bubble': isThinkBubble,
    'c-text-message__bubble': !isThinkBubble,
  });

  return (
    <div className={bubbleTextClasses}>
      {hasAvatar ? (
        <img alt="avatar icon" className="c-text-message__avatar" src={presignedUrl} />
      ) : (
        !isCharacterFrame && <div className="h-background--color-brand-secondary c-text-message__avatar" />
      )}
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: text,
        }}
        className={bubbleTextContentClasses}
      />
    </div>
  );
};

export { BubbleText };
