import React from 'react';

import { HORIZONTAL_ANGLE_OFFSETS, TAIL_ANGLES, TAIL_TYPES } from 'components/CartoonContent/CartoonContent.constants';

export interface TailProps {
  additionalClassNames?: string;
  angle: number;
  isTailVisible: boolean;
  tailType: string;
  width?: number
}

const Tail = ({ additionalClassNames = '', angle, isTailVisible, tailType, width = 30 }: TailProps) => {
  if (!isTailVisible) {
    return null;
  }

  return (
    <img
      className={`c-bubble-tail ${additionalClassNames}`}
      src={TAIL_TYPES[tailType]}
      alt=""
      style={{
        width: `${width}%`,
        transform: `translate(${HORIZONTAL_ANGLE_OFFSETS[angle] || '-50%'}, 0) rotate(${TAIL_ANGLES[Math.round(angle)]})`,
      }}
    />
  );
};

export { Tail };
