import React, { useEffect, useRef, useState } from 'react';

export interface ProgressIndicatorProps {
  current: number;
  total: number;
}

const ProgressIndicator = ({ current, total }: ProgressIndicatorProps) => {
  const radiusRef = useRef(null);

  const [strokeOffset, setStrokeOffset] = useState(null);

  const value = (current / total) * 100;

  useEffect(() => {
    if (!radiusRef.current) {
      return;
    }

    const radius = Number(radiusRef.current.getAttribute('r'));
    const circumference = Math.PI * (radius * 2);

    setStrokeOffset(((100 - value) / 100) * circumference);
  }, [value]);

  return (
    <div className="c-progress-indicator">
      <svg className="c-progress-indicator__svg" width="140" height="140" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <circle
          className="c-progress-indicator__outer"
          r="60"
          cx="70"
          cy="70"
          fill="transparent"
          strokeDasharray="377"
          strokeDashoffset="0"
        />
        <circle
          className="c-progress-indicator__bar"
          ref={radiusRef}
          r="60"
          cx="130"
          cy="70"
          fill="transparent"
          strokeDasharray="377"
          strokeDashoffset="0"
          transform="rotate(-90 100 100)"
          style={{ strokeDashoffset: `${strokeOffset}px` }}
        />
      </svg>
      <div className="c-progress-indicator__count">
        <span className="h5" style={{ whiteSpace: 'nowrap' }}>
          {current} of {total}
        </span>
      </div>
    </div>
  );
};

export { ProgressIndicator };
