import { CSSProperties } from 'react';

import { StudentLayout, TeacherLayout } from 'layouts';

const CARTOON_CSS_OPACITY_TRANSITIONS = {
  animatedImage: {
    easeInOut: 'opacity 0.15s ease-in-out',
    easeOutIn: 'opacity 0.2s ease-out-in',
  },
  bubbleText: {
    easeInOut: 'opacity 0.15s ease-in-out',
    easeOutIn: 'opacity 0.2s ease-out-in',
  },
  nonAnimatedImage: {
    easeInOut: 'opacity 0.15s ease-in-out',
    easeOutIn: 'opacity 0.2s ease-out-in',
  },
};

const CARTOON_IMAGE_BASE_STYLES = {
  height: '100%',
  width: '100%',
} as CSSProperties;

const CARTOON_IMAGE_CONTAINER_STYLES = {
  width: '100%',
};

const MODULE_CSS_SUFFIX_MAP = {
  'MDMA & emerging drugs': 'mdma',
};

const HORIZONTAL_ANGLE_OFFSETS = {
  3: '50%',
  9: '-50%',
};

const TAIL_ANGLES = {
  1: '-150deg',
  2: '-120deg',
  3: '-90deg',
  4: '-60deg',
  5: '-30deg',
  6: '0deg',
  7: '30deg',
  8: '60deg',
  9: '90deg',
  10: '120deg',
  11: '150deg',
  12: '180deg',
};

const TAIL_TYPES = {
  DEFAULT: '/images/default-tail.svg',
  THINK: '/images/think-tail.svg',
};

const USER_TYPE_LAYOUT_COMPONENT_MAP = {
  student: StudentLayout,
  teacher: TeacherLayout,
};

export {
  CARTOON_CSS_OPACITY_TRANSITIONS,
  CARTOON_IMAGE_BASE_STYLES,
  CARTOON_IMAGE_CONTAINER_STYLES,
  HORIZONTAL_ANGLE_OFFSETS,
  MODULE_CSS_SUFFIX_MAP,
  TAIL_ANGLES,
  TAIL_TYPES,
  USER_TYPE_LAYOUT_COMPONENT_MAP,
};
