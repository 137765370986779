import React, { CSSProperties, Fragment, useLayoutEffect, useState } from 'react';

import { FactCheck } from '../BubbleShapes/FactCheck/FactCheck';
import { StopAndThink } from '../BubbleShapes/StopAndThink/StopAndThink';
import { CARTOON_CSS_OPACITY_TRANSITIONS } from '../CartoonContent.constants';

import { BubbleText, EBubbleTextPosition } from './BubbleText/BubbleText';
import { SpeechBubble } from './SpeechBubble/SpeechBubble';

interface BubbleTextProps {
  isCartoonImageLoading: boolean;
  isLargerDevice: boolean;
  items: Array<any>;
  onStopAndThinkChangeHandler: (value: string) => void;
}

const BubbleTexts = ({ isCartoonImageLoading, isLargerDevice, items, onStopAndThinkChangeHandler }: BubbleTextProps) => {
  const [showFactCheck, setShowFactCheck] = useState(false);

  const [containerStyle, setContainerStyle] = useState<CSSProperties>(undefined);

  const {
    bubbleText: { easeInOut, easeOutIn },
  } = CARTOON_CSS_OPACITY_TRANSITIONS;

  useLayoutEffect(() => {
    setContainerStyle({
      opacity: isCartoonImageLoading ? 0 : 1,
      transition: isCartoonImageLoading ? easeInOut : easeOutIn,
    });
  }, [isCartoonImageLoading]);

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  const obj = {};
  const characterMap = items.map((r) => r.characterId).filter(onlyUnique);
  let side: EBubbleTextPosition = EBubbleTextPosition.Left;
  // eslint-disable-next-line no-restricted-syntax
  for (const key of characterMap) {
    obj[key] = side;
    side = side === EBubbleTextPosition.Left ? EBubbleTextPosition.Right : EBubbleTextPosition.Left;
  }

  return (
    <div style={containerStyle}>
      {isLargerDevice &&
        items.map((bubbleText) => {

          const { angle, id, isTailVisible, shape, text, tailType, w, x, y, hasShadow = false } = bubbleText;
          const hasTail = shape === 'RECTANGLE' || shape === 'OVAL' || shape === 'THINK';

          if (shape === 'FACT_CHECK') {
            setTimeout(() => {
              setShowFactCheck(true);
            }, 2500);
          }

          const isStopAndThinkNoInput = shape === 'STOP_AND_THINK_NO_INPUT';

          return (
            <Fragment key={id}>
              <SpeechBubble
                angle={angle}
                hasTail={hasTail}
                isTailVisible={isTailVisible}
                left={x}
                tailType={tailType}
                top={y}
                type={shape}
                width={w}
                bubbleText={text}
                hasShadow={hasShadow}
                onChangeHandler={onStopAndThinkChangeHandler}
              >
                <div
                  className="c-bubble-text"
                  dangerouslySetInnerHTML={{
                    __html: text,
                  }}
                />
              </SpeechBubble>

              {shape === 'FACT_CHECK' && showFactCheck && <FactCheck text={text} />}
              {(shape === 'STOP_AND_THINK' || shape === 'STOP_AND_THINK_NO_INPUT') && (
                <StopAndThink text={text} onChangeHandler={onStopAndThinkChangeHandler} shouldDisplayInputField={isStopAndThinkNoInput} />
              )}
            </Fragment>
          );
        })}
      {!isLargerDevice &&
        items.map((bubbleText) => {
          const { character, id, shape, text, characterId } = bubbleText || {};
          const { image, name } = character || {};

          const position = obj && obj[characterId] ? obj[characterId] : EBubbleTextPosition.Left;

          const isStopAndThink = shape === 'STOP_AND_THINK';
          const isStopAndThinkNoInput = shape === 'STOP_AND_THINK_NO_INPUT';
          const isThinkBubble = shape === 'THINK';
          const isCharacterFrame = name === 'Frame';

          return isStopAndThink || isStopAndThinkNoInput ? (
            <StopAndThink
              additionalClassNames="c-stop-and-think-mobile"
              shouldDisplayInputField={isStopAndThinkNoInput}
              text={text}
              onChangeHandler={onStopAndThinkChangeHandler}
            />
          ) : (
            <BubbleText
              avatar={image}
              isCharacterFrame={isCharacterFrame}
              isThinkBubble={isThinkBubble}
              position={position}
              key={id}
              text={text}
            />
          );
        })}
    </div>
  );
};

export { BubbleTexts };
