const getQuizData = (bubbleTexts) => {
  if (!bubbleTexts || !bubbleTexts.length) {
    return undefined;
  }

  const bubbleText = bubbleTexts[0];
  if (bubbleText.shape !== 'QUIZ') {
    return undefined;
  }

  const MULTI_RESPONSE_DELIMITER = '_::?::_';
  const CORRECT_RESPONSE_DELIMITER = '_::C::_';
  const PARTS_DELIMITER = '_::N::_';
  const QUESTION_DELIMITER = '_::_';
  const RESPONSE_OPTIONS_DELIMITER = '_::Q::_';
  const INFORMATION_DELIMITER = '_::I::_';

  const { text } = bubbleText;

  const parts = text.split(PARTS_DELIMITER);
  const responseOptions = parts[0].split(RESPONSE_OPTIONS_DELIMITER);
  const question = responseOptions[0].split(QUESTION_DELIMITER);
  const isInformation = responseOptions[0].includes(INFORMATION_DELIMITER);
  const isMultiResponse = responseOptions[0].includes(MULTI_RESPONSE_DELIMITER);
  const answerStyle = parts[4] ? JSON.parse(parts[4].replace(/'/g, '"')) : undefined;

  return {
    bubbleText,
    correctResponse: parseInt(parts[1], 10),
    isInformation,
    isMultiResponse,
    feedback: {
      correctText: parts[2],
      incorrectText: parts[3],
    },
    answerStyle,
    questionText: question[1],
    responseOptions: responseOptions.slice(1).map((option) => {
      const isForceCorrect = option.includes(CORRECT_RESPONSE_DELIMITER);
      const textWithoutSymbol = option.replace(CORRECT_RESPONSE_DELIMITER, '');

      return {
        ...option,
        isForceCorrect,
        text: textWithoutSymbol,
      };
    }),
  };
};

const getQuizQuestionIdentifier = (index: number): string => {
  return String.fromCharCode('a'.charCodeAt(0) + index);
};

export { getQuizData, getQuizQuestionIdentifier };
