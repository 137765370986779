import React, { useEffect, useRef, useState } from 'react';

import Switch from 'react-switch';
import { RoleType } from 'utils/functions/getRoleType';
import { getItem, removeItem, setItem } from 'utils/functions/localStorage';

import { Popup } from 'components/Shared/UI/Popup';

import { AUDIO_PLAYER_ERROR_COUNT, AUDIO_PLAYER_ERROR_TEXT } from './AudioPlayer.constants';

export interface AudioPlayerProps {
  hasAutoPlaySound: boolean;
  hasPlayButtonPulsing?: boolean;
  isAudioPlayerInformationPopupVisible: boolean;
  isAutoPlayEnabled: boolean;
  isNextSlideDisabled: boolean;
  onAutoPlayChangeHandler: () => void;
  onAutoPlayInfoDisplay: () => void;
  onAutoPlayMuteHandler: () => void;
  onEndHandler: () => void;
  onFullscreenHandler?: () => void;
  onLoadedHandler: () => void;
  onShareUrlHandler?: () => void;
  minimumDuration?: number;
  roleType: RoleType;
  source: string;
}

const TEXT = Object.freeze({
  audio: 'Audio',
  pause: 'Pause',
  play: 'Play',
  resume: 'Resume',
  stop: 'Stop',
});

const AudioPlayer = ({
  isAutoPlayEnabled,
  isAudioPlayerInformationPopupVisible,
  hasAutoPlaySound,
  hasPlayButtonPulsing = false,
  isNextSlideDisabled,
  onShareUrlHandler,
  onAutoPlayChangeHandler,
  onAutoPlayMuteHandler,
  onAutoPlayInfoDisplay,
  onFullscreenHandler,
  onEndHandler,
  onLoadedHandler,
  minimumDuration,
  source,
}: AudioPlayerProps) => {
  const [hasBeenShared, setHasBeenShared] = useState(false);
  const audioRef = useRef(null);

  const { readyState: audioReadyState } = audioRef.current || {};

  const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

  const handleAudioEnded = async () => {
    if (audioRef && minimumDuration) {
      const differenceDuration = minimumDuration - audioRef.current.duration;

      if (differenceDuration >= 0) {
        await delay(differenceDuration * 1000);
      } else {
        await delay(500);
      }
    }

    if (isNextSlideDisabled) {
      return;
    }

    onEndHandler();
  };

  const handleAudioPlayStatus = () => {
    if (audioReadyState < 4) {
      return;
    }

    if (isAutoPlayEnabled) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  };

  const handleAutoPlayChange = () => {
    onAutoPlayChangeHandler();
  };

  const handleMuteAudio = () => {
    onAutoPlayMuteHandler();
  };

  useEffect(() => {
    audioRef.current.pause();
    if (!source) {
      return;
    }

    setHasBeenShared(false);
    audioRef.current.load();
    audioRef.current.pause();
    setTimeout(() => {
      audioRef.current.play();
    }, 500);
  }, [source]);

  useEffect(() => {
    if (audioReadyState < 4) {
      return;
    }

    onLoadedHandler();
  }, [audioReadyState]);

  useEffect(() => {
    handleAudioPlayStatus();
  }, [isAutoPlayEnabled]);

  useEffect(() => {
    audioRef.current.muted = !hasAutoPlaySound;
    audioRef.current.play();
  }, [hasAutoPlaySound]);

  useEffect(() => {
    const promise = audioRef.current.play();

    if (!promise) {
      return;
    }

    promise
      .then(() => {
        audioRef.current.muted = !hasAutoPlaySound;
        audioRef.current.play();
      })
      .catch((error: Error) => {
        if (!error.message.includes(AUDIO_PLAYER_ERROR_TEXT)) {
          return;
        }

        const errorCount = Number(getItem('errorCount'));
        setItem('errorCount', String(errorCount + 1));

        if (!errorCount) {
          onAutoPlayInfoDisplay();
        }

        if (errorCount === AUDIO_PLAYER_ERROR_COUNT) {
          removeItem('errorCount');
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { audio: audioText, pause: pauseText, play: playText, resume: resumeText } = TEXT;

  return (
    <>
      {source && (
        <>
          <button
            onClick={handleAutoPlayChange}
            className={`c-player-control ${hasPlayButtonPulsing ? 'pulse' : ''}`}
            title={`${isAutoPlayEnabled ? pauseText : resumeText} autoplay`}
            type="button"
          >
            {isAutoPlayEnabled ? <ion-icon name="pause" /> : <ion-icon name="play" />}
          </button>
          <button
            onClick={handleMuteAudio}
            className="c-player-control"
            title={`${hasAutoPlaySound ? 'Volume on' : 'Muted'} `}
            type="button"
          >
            {hasAutoPlaySound ? <ion-icon name="volume-medium" /> : <ion-icon name="volume-mute" />}
          </button>
        </>
      )}
      {onFullscreenHandler && (
        <button onClick={onFullscreenHandler} className="c-player-control" title={`Fullscreen`} type="button">
          <ion-icon name="expand" />
        </button>
      )}

      {onShareUrlHandler && (
        <button
          onClick={() => {
            onShareUrlHandler();
            setHasBeenShared(true);
          }}
          className="c-player-control"
          title={`Share`}
          type="button"
        >
          {hasBeenShared && <ion-icon name="checkmark-done-outline" class="animated-little-icon" />}
          {!hasBeenShared && <ion-icon name="share-outline" />}
        </button>
      )}

      {isAudioPlayerInformationPopupVisible && (
        <Popup additionalClassNames="popup__audio-player" onClickClosePopup={onAutoPlayInfoDisplay}>
          <div className="popup__audio-player-content">
            <h1>Information</h1>
            <p>
              Please allow Autoplay in your{' '}
              <a
                href="https://ekardo.s3-ap-southeast-2.amazonaws.com/production/project/Gelada-Project-6-531ecc29-b44f-4234-a91a-3d11be5cb63c/Image/public/safariautoplay.png"
                target="_blank"
                rel="noopener noreferrer"
              >
                Safari
              </a>{' '}
              or{' '}
              <a
                href="https://ekardo.s3-ap-southeast-2.amazonaws.com/production/project/Gelada-Project-6-531ecc29-b44f-4234-a91a-3d11be5cb63c/Image/public/firefoxautoplay.png"
                target="_blank"
                rel="noopener noreferrer"
              >
                Firefox
              </a>{' '}
              browser settings. For better performance and usability, use Chrome browser to play the cartoons.
            </p>
          </div>
        </Popup>
      )}

      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio autoPlay={isAutoPlayEnabled} className="h-hide" ref={audioRef} onEnded={handleAudioEnded} controls>
        <source src={source} type="audio/mpeg" />
      </audio>
    </>
  );
};

export { AudioPlayer };
