import React from 'react';

import { IShape } from '../Shape.interfaces';

export type BubbleProps = IShape;

const Bubble = ({ baseStyles }: BubbleProps) => (
  <svg style={baseStyles} className="c-cartoon__image" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 205 132">
    <defs />
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <circle className="cls-1" cx="118" cy="43" r="43" />
        <circle className="cls-1" cx="63" cy="45" r="32" />
        <circle className="cls-1" cx="35" cy="89" r="29" />
        <circle className="cls-1" cx="82.5" cy="97.5" r="34.5" />
        <circle className="cls-1" cx="132" cy="97" r="25" />
        <circle className="cls-1" cx="170" cy="71" r="35" />
        <circle className="cls-1" cx="25" cy="58" r="25" />
      </g>
    </g>
  </svg>
);

export { Bubble };
