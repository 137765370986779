import React, { useEffect, useRef, useState } from 'react';

interface ISpeechInput {
  text?: string;
  top: number;
  left: number;
  width: number;
  onChangeHandler: (value: string) => void;
}

const SpeechInput = ({ text, top, left, width, onChangeHandler }: ISpeechInput) => {

  const textareaRef = useRef(null);
  const [inputValue, setInputValue] = useState('');
  const isYAxisSpecified = Boolean(top);
  const isXAxisSpecified = Boolean(left);
  const isWidthSpecified = Boolean(width);



  const handleChange = (value) => {
    onChangeHandler(value);
    setInputValue(value);
  };

  useEffect(() => {
    if (!textareaRef.current) {
      return;
    }

    onChangeHandler(textareaRef.current.value);
  }, [textareaRef]);

  useEffect(() => {
    setInputValue('');
  }, [text]);

  return (
    <div
      className="c-cartoon-input"
      style={{
        top: isYAxisSpecified && `${top}%`,
        left: isXAxisSpecified && `${left}%`,
        width: isWidthSpecified && `${width}%`,
      }}
    >
      <textarea
          className="c-cartoon-input__textarea"
          id="id_new_text_area"
          ref={textareaRef}
          value={inputValue}
          placeholder="Please type your answer here"
          onChange={({ target }) => handleChange(target.value)}
        />
    </div>
  );
};

export { SpeechInput };
