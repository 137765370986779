import React, { CSSProperties, LegacyRef, ReactNode } from 'react';

import { TAIL_ANGLES, TAIL_TYPES } from 'components/CartoonContent/CartoonContent.constants';

import { IShape } from '../Shape.interfaces';

export interface RectangleProps extends IShape {
  angle: number;
  bubbleTailWidth?: number;
  children?: ReactNode;
  isTailVisible?: boolean;
  overrideOrAdditionalStyles?: CSSProperties;
  speechBubbleRef: LegacyRef<HTMLDivElement>;
  tailType?: string;
}

const Rectangle = ({
  angle,
  baseStyles,
  children,
  isTailVisible = false,
  overrideOrAdditionalStyles,
  speechBubbleRef,
  tailType,
}: RectangleProps) => (
  <div
    className="c-cartoon__image c-cartoon__rectangle"
    ref={speechBubbleRef}
    style={{
      ...baseStyles,
      ...overrideOrAdditionalStyles,
    }}
  >
    {isTailVisible && (
      <img
        className="c-bubble-tail"
        src={TAIL_TYPES[tailType]}
        alt=""
        style={{
          width: '15%',
          transform: `translate(-50%, 0) rotate(${TAIL_ANGLES[Math.round(angle)]})`,
        }}
      />
    )}
    <div className="l-position--relative">{children}</div>
  </div>
);

export { Rectangle };
