export interface IPopupErrorMessages {
  messages?: string[];
}
export const PopupErrorMessages = ({ messages }: IPopupErrorMessages) => {
  return (
    <div className="h-color-red m-t-30">
      <ul>{messages?.map((r) => <li>{r}</li>)}</ul>
    </div>
  );
};
