import React, { ReactNode, useRef, useState, useEffect } from 'react';

import { getCssPositioningValue } from 'utils/functions/getCssPositioningValue';

import { Bubble } from '../../BubbleShapes/Bubble/Bubble';
import { Ellipse } from '../../BubbleShapes/Ellipse/Ellipse';
import { Label } from '../../BubbleShapes/Label/Label';
import { Rectangle } from '../../BubbleShapes/Rectangle/Rectangle';
import { Tail } from '../../BubbleShapes/Tail/Tail';
import { Blast } from 'components/CartoonContent/BubbleShapes/Blast/Blast';
import { Para } from 'components/CartoonContent/BubbleShapes/Para/Para';
import { Chat } from 'components/CartoonContent/BubbleShapes/Chat/Chat';
import { SpeechInput } from 'components/CartoonContent/BubbleShapes/SpeechInput/SpeechInput';
import { Title } from 'components/CartoonContent/BubbleShapes/Title/Title';

interface SpeechBubbleProps {
  angle?: number;
  bubbleText?: string;
  children: ReactNode;
  hasTail?: boolean;
  hasShadow?: boolean;
  isTailVisible?: boolean;
  left: number;
  tailType?: string;
  top: number;
  type?: string;
  width: number;
  onChangeHandler?: (value: string) => void;
}

const SpeechBubble = ({ angle, children, hasTail, isTailVisible, left, tailType, top, type, width, bubbleText, onChangeHandler, hasShadow = false }: SpeechBubbleProps) => {
  const speechBubbleRef = useRef(null);
  const [bubbleTailWidth, setBubbleTailWidth] = useState(0);

  const baseStyles = {
    display: 'block',
    fill: '#fff',
    left: '50%',
    top: '50%',
    width: 'calc(100% + 2rem)',
  };

  const overrideOrAdditionalStyles = {
    left: getCssPositioningValue(left),
    top: getCssPositioningValue(top),
    width: getCssPositioningValue(width),
  };

  const shapeTypeMap = {
    LABEL: <Label baseStyles={overrideOrAdditionalStyles}>{children}</Label>,
    OVAL: (
      <Ellipse
        angle={angle}
        hasShadow={hasShadow}
        isTailVisible={isTailVisible}
        left={left}
        tailType={tailType}
        top={top}
        width={width}
      >
        {children}
      </Ellipse>
    ),
    BLAST: <Blast angle={angle} isTailVisible={isTailVisible} left={left} text={bubbleText} top={top} width={width} />,
    CHAT_FROM: <Chat left={left} text={bubbleText} top={top} width={width} isReceived />,
    CHAT_TO: <Chat left={left} text={bubbleText} top={top} width={width} />,
    INPUT: <SpeechInput left={left} text={bubbleText} top={top} width={width} onChangeHandler={onChangeHandler} />,
    QUIZ: (
      <Rectangle
        angle={angle}
        baseStyles={baseStyles}
        bubbleTailWidth={bubbleTailWidth}
        overrideOrAdditionalStyles={overrideOrAdditionalStyles}
        speechBubbleRef={speechBubbleRef}
      >
        {children}
      </Rectangle>
    ),
    PARA: <Para left={left} text={bubbleText} top={top} width={width} />,
    RECTANGLE: (
      <Rectangle
        angle={angle}
        baseStyles={baseStyles}
        bubbleTailWidth={bubbleTailWidth}
        isTailVisible={hasTail}
        overrideOrAdditionalStyles={overrideOrAdditionalStyles}
        speechBubbleRef={speechBubbleRef}
        tailType={tailType}
      >
        {children}
      </Rectangle>
    ),
    THINK: <Bubble baseStyles={baseStyles} />,
    TITLE: <Title left={left} text={bubbleText} top={top} width={width} />,
  };

  const shapeTypeKeys = Object.keys(shapeTypeMap);
  const svgShapeTypes = ['THINK'];
  const isSvgShapeType = svgShapeTypes.includes(type);

  useEffect(() => {
    if (!speechBubbleRef.current) {
      return;
    }

    setBubbleTailWidth(speechBubbleRef.current.clientWidth);
  }, []);

  if (!shapeTypeKeys.includes(type)) {
    return null;
  }

  if (!isSvgShapeType) {
    return shapeTypeMap[type];
  }

  return (
    <div
      className="c-cartoon__bubble"
      ref={speechBubbleRef}
      style={{
        display: 'block',
        ...overrideOrAdditionalStyles,
      }}
    >
      <div className="l-position--relative">
        {shapeTypeMap[type]}
        {hasTail && <Tail angle={angle} isTailVisible={isTailVisible} tailType={tailType} />}
        <div className="l-position--relative">{children}</div>
      </div>
    </div>
  );
};

export { SpeechBubble };
