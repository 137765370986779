import React, { CSSProperties, useEffect, useLayoutEffect, useState } from 'react';

import cx from 'classnames';

import { CARTOON_CSS_OPACITY_TRANSITIONS, CARTOON_IMAGE_CONTAINER_STYLES, CARTOON_IMAGE_BASE_STYLES } from './CartoonContent.constants';

interface CartoonAssetProps {
  asset: any;
  isLargerDevice?: boolean;
  isLoading?: boolean;
  items?: Array<any>;
  onImageLoaded: () => void;
}

const CartoonAsset = ({ asset, isLoading, isLargerDevice, items, onImageLoaded }: CartoonAssetProps) => {
  const { isAnimatedImage, presignedUrl } = asset;

  const [animatedImageStyle, setAnimatedImageStyle] = useState<CSSProperties>(undefined);
  const [imageStyle, setImageStyle] = useState<CSSProperties>(undefined);
  const [isCartoonBubbleStopAndThink, setIsCartoonBubbleStopAndThink] = useState(false);
  useEffect(() => {
    if (!(items && items.length)) {
      setIsCartoonBubbleStopAndThink(false);

      return;
    }

    const [firstCartoonItem] = items;
    const { shape } = firstCartoonItem;

    setIsCartoonBubbleStopAndThink(shape === 'STOP_AND_THINK' || shape === 'STOP_AND_THINK_NO_INPUT');
  }, [items]);

  useLayoutEffect(() => {
    const isAnimatedImageLoading = isAnimatedImage && isLoading;
    const isNonAnimatedImageLoading = !isAnimatedImage && isLoading;

    const {
      animatedImage: { easeInOut: animatedImageEaseInOut, easeOutIn: animatedImageEaseOutIn },
      nonAnimatedImage: { easeInOut: nonAnimatedImageEaseInOut, easeOutIn: nonAnimatedImageEaseOutIn },
    } = CARTOON_CSS_OPACITY_TRANSITIONS;

    setAnimatedImageStyle({
      opacity: isAnimatedImageLoading ? 0 : 1,
      transition: isAnimatedImageLoading ? animatedImageEaseInOut : animatedImageEaseOutIn,
    });

    setImageStyle({
      opacity: isNonAnimatedImageLoading ? 0 : 1,
      transition: isNonAnimatedImageLoading ? nonAnimatedImageEaseInOut : nonAnimatedImageEaseOutIn,
    });
  }, [isAnimatedImage, isLoading]);

  return (
    <div style={CARTOON_IMAGE_CONTAINER_STYLES}>
      {isAnimatedImage ? (
        <object
          style={{
            ...CARTOON_IMAGE_BASE_STYLES,
            ...animatedImageStyle,
          }}
          onLoad={() => {
            onImageLoaded();
          }}
          title="cartoon slide"
          type="image/svg+xml"
          data={presignedUrl}
        />
      ) : (
        <img
          id="cartoon-image"
          className={cx({ 'stop-and-think-image': isCartoonBubbleStopAndThink && !isLargerDevice })}
          onLoad={() => {
            const image = document.querySelector('#cartoon-image') as any;
            const isLoaded = image.complete && image.naturalHeight !== 0;
            if (isLoaded) {
              onImageLoaded();
            }
          }}
          style={{
            ...CARTOON_IMAGE_BASE_STYLES,
            ...imageStyle,
          }}
          alt=""
          src={presignedUrl}
        />
      )}
    </div>
  );
};

export default CartoonAsset;
