import React, { useState, useEffect, useRef } from 'react';

import cx from 'classnames';

export interface StopAndThinkProps {
  additionalClassNames?: string;
  onChangeHandler: (value: string) => void;
  shouldDisplayInputField?: boolean;
  text: string;
}

const StopAndThink = ({ additionalClassNames, shouldDisplayInputField, onChangeHandler, text }: StopAndThinkProps) => {
  const stopAndThinkClassNames = cx('c-stop-think', additionalClassNames);

  const [stopAndThinkValue, setStopAndThinkValue] = useState('');

  const textareaRef = useRef(null);

  const handleChange = (value) => {
    onChangeHandler(value);
    setStopAndThinkValue(value);
  };

  useEffect(() => {
    if (!textareaRef.current) {
      return;
    }

    onChangeHandler(textareaRef.current.value);
  }, [textareaRef]);

  useEffect(() => {
    setStopAndThinkValue('');
  }, [text]);

  return (
    <div className={stopAndThinkClassNames}>
      <h3>Stop and think</h3>
      <div dangerouslySetInnerHTML={{ __html: text }} />
      {!shouldDisplayInputField ? (
        <textarea
          className="c-stop-think__textarea"
          id="stop-think"
          ref={textareaRef}
          value={stopAndThinkValue}
          title="Stop and think"
          onChange={({ target }) => handleChange(target.value)}
        />
      ) : null}
    </div>
  );
};

export { StopAndThink };
