import React from 'react';

import cx from 'classnames';

import { Button } from 'components/Shared/Inputs/Button';

export interface QuizButtonProps {
  className?: string;
  identifier: string;
  onClick?: () => void;
  text: string;
}

const QuizButton = ({ className, identifier, onClick, text }: QuizButtonProps) => {
  return (
    <Button className={cx('c-quiz-button', className)} color="tertiary" onClick={onClick}>
      <div className="c-quiz-button__index">{identifier}</div>
      <span
        className="c-quiz-button__text"
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
    </Button>
  );
};

export { QuizButton };
