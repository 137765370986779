import React from 'react';

import { NAVIGATION_DIRECTION, SLIDE } from './SlideNavigation.constants';

export interface SliderNavigationProps {
  isDisabled?: boolean;
  isPrevious?: boolean;
  onClickHandler: () => void;
  isPulsing?: boolean;
}

const SlideNavigation = ({ isDisabled = false, isPrevious = false, onClickHandler, isPulsing = false }: SliderNavigationProps) => {
  const { next, previous } = NAVIGATION_DIRECTION;

  return (
    <button
      className={`${isPulsing ? 'pulse' : ''} p-student__slide-toggle p-student__slide-toggle--${
        isPrevious ? previous.toLowerCase() : next.toLowerCase()
      }`}
      disabled={isDisabled}
      type="button"
      onClick={onClickHandler}
    >
      <img src="/images/icon-arrow.svg" alt={isPrevious ? `${previous} ${SLIDE}` : `${next} ${SLIDE}`} />
    </button>
  );
};

export { SlideNavigation };
