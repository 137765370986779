import { gql } from '@apollo/client';

const GetCartoonSlideQuery = gql`
  query GetCartoonSlideQuery($pageUrl: String!) {
    contentPage {
      getContentPagePerName(
        contentPageUrl: $pageUrl
        projectId: "${process.env.REACT_APP_PROJECT_ID}"
      ) {
        asset {
          assetId
          presignedUrl
          bubbleTexts {
            angle
            character {
              id
              image {
                presignedUrl
                contentType
                s3Key
              }
              name
              projectId
            }
            characterId
            id
            isTailVisible
            shape
            tailType
            sort
            text
            hasShadow
            w
            x
            y
          }
          contentType
          fileName
          fileSizeInBytes
          isAnimatedImage
          presignedUrl
          s3Key
          type
        }
        audio {
          assetId
          presignedUrl
          contentType
          fileName
          fileSizeInBytes
          presignedUrl
          s3Key
          type
        }
        id
        sort
        url
      }
    }
  }
`;

const GetUnitsQuery = gql`
  query GetUnitsQuery($unitId: Int!) {
    unit {
      get(unitIds: [$unitId]) {
        contentGroup {
          groupingContentGroup {
            id
          }
          id
        }
        mode
      }
    }
  }
`;

export { GetCartoonSlideQuery, GetUnitsQuery };
