import React, { ReactNode } from 'react';

import cx from 'classnames';

import { Button } from 'components/Shared/Inputs/Button';

export interface PopUpProps {
  additionalClassNames?: string;
  children: ReactNode;
  footer?: ReactNode;
  header?: ReactNode;
  onClickClosePopup?: () => void;
}

const Popup: React.FC<PopUpProps> = ({ additionalClassNames, children, header, footer, onClickClosePopup }) => {
  const popupInnerClassNames = cx('popup_inner l-flex l-flex-gap', additionalClassNames);

  return (
    <div className="popup">
      <div className={popupInnerClassNames}>
        <div className="popup__header">
          {onClickClosePopup && (
            <Button onClick={onClickClosePopup} className="l-flex__item--row close-select-task-pop-up" icon="cross" iconPosition="right" />
          )}
          {header}
        </div>
        <div className="popup__body">{children}</div>
        <div className="popup__footer">{footer}</div>
      </div>
    </div>
  );
};

export { Popup };
