import React, { CSSProperties, useLayoutEffect, useState } from 'react';

import { useMediaQuery } from 'react-responsive';

import { SpeechBubble } from '../BubbleTexts/SpeechBubble/SpeechBubble';
import { getQuizQuestionIdentifier } from '../CartoonContent.helpers';

import { QuizButton } from './QuizButton/QuizButton';
import { QuizFeedback } from './QuizFeedback/QuizFeedback';

interface QuizProps {
  data: any;
  isCartoonImageLoading: boolean;
  onResponseChangeHandler: (response: any) => void;
  response: any;
}

const Quiz = ({ data, isCartoonImageLoading, onResponseChangeHandler, response }: QuizProps) => {
  const isLargerDevice = useMediaQuery({ query: '(min-width: 768px)' });

  const [containerStyle, setContainerStyle] = useState<CSSProperties>(undefined);

  const handleResponseSelected = (index: number, text: string, isForceCorrect: boolean, isMultiResponse: boolean) => {
    const {
      correctResponse,
      feedback: { correctText, incorrectText },
    } = data;

    const isCorrectResponse = isMultiResponse ? isForceCorrect : index + 1 === correctResponse;

    const actualResponse = {
      feedback: isCorrectResponse ? correctText : incorrectText,
      isForceCorrect,
      index,
      isCorrectResponse,
      text,
    };

    onResponseChangeHandler(actualResponse);
  };

  useLayoutEffect(() => {
    setContainerStyle({
      opacity: isCartoonImageLoading ? 0 : 1,
      transition: isCartoonImageLoading ? 'opacity 0.15s ease-in-out' : 'opacity 1s ease-out-in',
    });
  }, [isCartoonImageLoading]);

  const {
    bubbleText: { shape, sort, w, x, y, hasShadow = false },
    isInformation,
    isMultiResponse,
    questionText,
    responseOptions,
    answerStyle,
  } = data;

  const quizFeedbackContainerClassName = isLargerDevice ? 'c-quiz--desktop' : '';

  const feedBackStyle = answerStyle
  ? {
      left: `${answerStyle.x}%`,
      top: `${answerStyle.y}%`,
      width: `${answerStyle.w}%`,
    }
  : {
      bottom: '2rem',
      right: '2rem',
      width: '45%',
    }

  return (
    <div style={containerStyle}>
      <SpeechBubble key={sort} left={x} top={y} type={shape} width={w} bubbleText={questionText} hasShadow={hasShadow}>
        <strong className="h-color--brand-secondary">{questionText}</strong>
      </SpeechBubble>
      <div className={quizFeedbackContainerClassName} style={isLargerDevice ? feedBackStyle: {}}>
        {response ? (
          <QuizFeedback
            feedback={response.feedback}
            identifier={getQuizQuestionIdentifier(response.index)}
            isCorrectResponse={response.isForceCorrect || response.isCorrectResponse}
            isInformation={isInformation}
            text={response.text}
          />
        ) : (
          responseOptions.map((responseOption, index) => {
            const identifier = getQuizQuestionIdentifier(index);
            const { isForceCorrect, text } = responseOption;

            return (
              <QuizButton
                identifier={identifier}
                key={identifier}
                text={text}
                onClick={() => handleResponseSelected(index, text, isForceCorrect, isMultiResponse)}
              />
            );
          })
        )}
      </div>
    </div>
  );
};

export { Quiz };
