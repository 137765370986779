import React from 'react';

import { QuizButton } from '../QuizButton/QuizButton';

interface QuizFeedbackProps {
  feedback: string;
  identifier: string;
  isCorrectResponse: boolean;
  isInformation: boolean;
  text: string;
}

const CORRECT = 'correct';
const INCORRECT = 'incorrect';

const QuizFeedback = ({ feedback, identifier, isCorrectResponse, isInformation = false, text }: QuizFeedbackProps) => {
  const rightOrWrongResponseSuffix = isCorrectResponse ? 'tick' : 'cross';
  const iconSuffix = isInformation ? 'info' : rightOrWrongResponseSuffix;

  return (
    <div className="c-quiz--desktop__inner">
      <QuizButton className="c-quiz-button--selected" identifier={identifier} text={text} />
      <div className={`c-quiz-feedback c-quiz-feedback--${isCorrectResponse || isInformation ? CORRECT : INCORRECT}`}>
        <div className="c-quiz-feedback__icon">
          <img alt={`Quiz answer is ${isCorrectResponse ? CORRECT : INCORRECT}`} src={`/images/icon-${iconSuffix}.svg`} />
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html: feedback,
          }}
        />
      </div>
    </div>
  );
};

export { QuizFeedback };
