import React from 'react';

interface IBlast {
  text?: string;
  top: number;
  left: number;
  width: number;
  isTailVisible?: boolean;
  angle?: number;
}

const Blast = ({ text, top, left, width, isTailVisible = false, angle }: IBlast) => {
  const isYAxisSpecified = Boolean(top);
  const isXAxisSpecified = Boolean(left);
  const isWidthSpecified = Boolean(width);

  return (
    <div
      className="c-cartoon-blast-container"
      style={{
        top: isYAxisSpecified && `${top}%`,
        left: isXAxisSpecified && `${left}%`,
        width: isWidthSpecified && `${width}%`,
      }}
    >
      <div className="c-cartoon-blast">
        <div
          className="c-cartoon-blast__text"
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
      </div>
      {isTailVisible && <div className={`c-cartoon-blast__tail c-cartoon-blast__tail--${angle}`} />}
    </div>
  );
};

export { Blast };
