import React, { ReactNode } from 'react';

import { Tail } from '../Tail/Tail';

export interface EllipseProps {
  hasShadow?: boolean;
  children?: ReactNode;
  top: number;
  left: number;
  width: number;
  isTailVisible?: boolean;
  angle?: number;
  tailType: string;

};

const Ellipse = ({ hasShadow = false, top, left, width = 0, isTailVisible, angle, tailType, children }: EllipseProps) => {
  const isYAxisSpecified = Boolean(top);
  const isXAxisSpecified = Boolean(left);
  const isWidthSpecified = Boolean(width);

  const tailWidthIncrement = 4;
  const ellipsisIncrement = 2
  const maxTailWidth = 25;

  const getSmallerNumber = (a: number): number => {
    return a < maxTailWidth ? a : maxTailWidth;
  };

  const tailWidth = getSmallerNumber(width + tailWidthIncrement);


  return (
    <>
      <div
        className={`c-cartoon-ellipsis ${hasShadow ? 'c-cartoon-ellipsis--shadowed' : ''}`}
        style={{
          top: isYAxisSpecified && `${top}%`,
          left: isXAxisSpecified && `${left}%`,
          width: isWidthSpecified && `${width + ellipsisIncrement}%`,
          minHeight: isWidthSpecified && `${width / ellipsisIncrement}%`,
          padding: isWidthSpecified && `${width}px`,
        }}
      >
        <div
          className="c-cartoon-ellipsis__inner"
          style={{
            margin: isWidthSpecified && `${width}px`,
          }}
        >
          {children}
        </div>
        <Tail width={isWidthSpecified && tailWidth} additionalClassNames="c-cartoon-ellipsis__tail" angle={angle} tailType={tailType} isTailVisible={isTailVisible} />
      </div>
    </>
  );
};

export { Ellipse };
