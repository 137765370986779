import React, { useState } from 'react';

export interface FactCheckProps {
  text: string;
}

const FactCheck = ({ text }: FactCheckProps) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="c-factcheck__container">
      <div className={`c-factcheck u-fadeup c-factcheck--${isOpen ? 'open' : 'closed'}`}>
        <div className="c-factcheck__image" />
        <div className="c-factcheck__content">
          <div className="c-factcheck__header">
            <h5>Fact check</h5>
            <button className="c-factcheck__button" onClick={handleToggle} title={`${isOpen ? 'Close' : 'Open'} factcheck`} type="button">
              <img alt={`${isOpen ? 'Close' : 'Open'} factcheck`} src="/images/circle-down.svg" />
            </button>
          </div>
          <div className="c-factcheck__body">{text}</div>
        </div>
      </div>
    </div>
  );
};

export { FactCheck };
