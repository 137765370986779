import React, { ReactNode } from 'react';

import { IShape } from '../Shape.interfaces';

export interface LabelProps extends IShape {
  children: ReactNode;
}

const Label = ({ baseStyles, children }: LabelProps) => (
  <div style={baseStyles} className="c-cartoon-label">
    <div className="c-cartoon-label__content">{children}</div>
  </div>
);

export { Label };
